export const ConstantsHelper = {
    // passRegxStr: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
    passRegxStr: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{8,}$/,
    linkRegxStr:
      /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})))(?::\d{2,5})?(?:[/?#]\S*)?$/i,
    emailIdRegex:
      /^[a-z0-9][a-z0-9-_\.]+@([a-z]|[a-z0-9]?[a-z0-9-]+[a-z0-9])\.[a-z0-9]{2,10}(?:\.[a-z]{2,10})?$/i,
    nameRegex: /^[a-zA-Z]+[ ]{0,1}[a-zA-Z ]*$/,
    gstNoRegex:
      /^([0-9]{2}[a-zA-Z]{4}([a-zA-Z]{1}|[0-9]{1})[0-9]{4}[a-zA-Z]{1}([a-zA-Z]|[0-9]){3}){0,15}$/,
    cardHolderNameRegex: /^(?![0-9]*$)[a-zA-Z0-9]+[ ]{0,1}[a-zA-Z0-9 ]*$/,
    noRegex: /[^0-9]/g,
    // locationNameRegex: /^([A-Za-z0-9_./&,\s]*)$/,
    locationNameRegex: /^([\p{L}\p{M}A-Za-z0-9_./&,\s]*)$/u,
    mobileNoRegex: /^(?!0+$)[0-9\(\)-]{10,}$/,
    AllowImages : { 'image/*': ['.png', '.jpg', '.jpeg', '.webp'] }
}