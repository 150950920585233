import React from "react";
import { Box, CircularProgress } from "@mui/material";

const CircleProgress = () => {
  const styles = {
    wrapper: {
      position: "fixed",
      top: 0,
      left: 0,
      width: "100%",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      backgroundColor: "rgba(255, 255, 255, 0.8)", // Optional: semi-transparent background
      zIndex: 9999, // Ensure it overlays other elements
    },
  };

  return (
    <Box sx={styles.wrapper}>
      <CircularProgress />
    </Box>
  );
};

export default CircleProgress;
