import { Avatar, Box, Typography, TextField, InputAdornment, Select, MenuItem } from '@mui/material'
import ColorsHelper from '../../shared/ColorHelper'
import CIcon from '../../UI/CIcon/CIcon'
import { useDropzone } from 'react-dropzone';
import { ConstantsHelper } from '../../constants/ConstantsHelprer';
import { LoadingButton } from '@mui/lab';
import { useState } from 'react';
import DatePicker from "react-datepicker";
import { styled } from "@mui/material/styles";
import "react-datepicker/dist/react-datepicker.css";
import { useNavigate } from 'react-router-dom';
import { RouteName } from '../../constants/routeNameConstant';

const StyledDatePickerWrapper = styled("div")(({ theme }) => ({
    "& .react-datepicker": {
        fontFamily: theme.typography.fontFamily,
        border: `1px solid ${theme.palette.divider}`,
        borderRadius: theme.shape.borderRadius,
        boxShadow: theme.shadows[3],
        width: '100%'
    },
    "& .react-datepicker__header": {
        backgroundColor: theme.palette.background.paper,
        borderBottom: `1px solid ${theme.palette.divider}`,
    },
    "& .react-datepicker__day--selected": {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
    },
    '& > div': { width: '100%' }
}));

const ProfileSetting = () => {

    const navigate = useNavigate()

    // State to handle image preview
    const [image, setImage] = useState<string | null>(null);
    const [startDate, setStartDate] = useState<any>(new Date());
    const [gender, setGender] = useState("Male");

    const handleGenderChange = (event: any) => {
        setGender(event.target.value);
    };

    const handleBack = () => {
        navigate(RouteName.PROFILE_VIEW)
    }

    const handleImageUpload = (acceptedFiles: File[]) => {
        if (acceptedFiles && acceptedFiles.length > 0) {
            const file = acceptedFiles[0];
            const reader = new FileReader();
            reader.onloadend = () => {
                setImage(reader.result as string); // Set the image as base64 URL
            };
            reader.readAsDataURL(file); // Read the file as data URL (base64)
        }
    };

    // Correctly type the accept prop
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: handleImageUpload,
        accept: ConstantsHelper.AllowImages, // Cast the string to the 'Accept' type
    });

    return (
        <Box sx={{ bgcolor: ColorsHelper.backColor }}>
            <Box sx={{
                height: 'calc(100dvh - 0.5rem)',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                gap: 2
            }}>
                <Box sx={{ display: 'flex', alignItems: 'center', gap: 12, mx: 2, mt: 2 }}>
                    <Box component={CIcon} icon={"eva:arrow-back-fill"} onClick={handleBack} />
                    <Typography sx={{ fontSize: '1.2rem', textAlign: 'center', fontWeight: 600 }}>Your Profile</Typography>
                </Box>

                <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', borderRadius: '30px', bgcolor: ColorsHelper.whiteClr, mx: 2, p: 2, gap: 2 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
                        {image ? (
                            <Box
                                component="img"
                                src={image}
                                alt="Uploaded Avatar"
                                sx={{
                                    width: 64,
                                    height: 64,
                                    borderRadius: '50%',
                                    objectFit: 'cover',
                                    cursor: 'pointer',
                                }}
                            />
                        ) : (
                            <Avatar
                                sx={{
                                    bgcolor: ColorsHelper.mainColorLighter,
                                    color: ColorsHelper.mainColor,
                                    cursor: 'pointer',
                                    fontSize: '3rem',
                                    p: 1,
                                }}
                            />
                        )}

                        {/* Dropzone for file upload */}
                        <section>
                            <div
                                {...getRootProps()}
                            >
                                <input {...getInputProps()} />
                                <LoadingButton
                                    variant="outlined"
                                    size='small'
                                    startIcon={<CIcon icon={'qlementine-icons:cloud-up-16'} />}
                                    sx={{
                                        borderColor: ColorsHelper.buttonColor,
                                        color: ColorsHelper.buttonColor,
                                        borderRadius: '8px',
                                    }}
                                >
                                    Upload Photo
                                </LoadingButton>
                            </div>
                        </section>
                    </Box>

                    <Box >
                        <Typography sx={{ fontSize: '0.8rem', mb: 0.8, color: ColorsHelper.blackClr80 }}>Your Name</Typography>
                        <TextField
                            fullWidth
                            size="small"
                            placeholder="Your name"
                            value="Alan Watson"
                            sx={{
                                color: ColorsHelper.textColor,
                                borderRadius: '8px',
                                '& .MuiInputBase-root': {
                                    borderRadius: '8px',
                                },
                            }}
                        />
                    </Box>

                    <Box >
                        <Typography sx={{ fontSize: '0.8rem', mb: 0.8, color: ColorsHelper.blackClr80 }}>Mobile number</Typography>
                        <TextField
                            fullWidth
                            type='tel'
                            size="small"
                            placeholder="Your Mobile Number"
                            value="+210 123-4560"
                            sx={{
                                color: ColorsHelper.textColor,
                                borderRadius: '8px',  // Border radius for the overall TextField
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <span style={{ color: ColorsHelper.mainColor, cursor: 'pointer' }}>Change</span>
                                    </InputAdornment>
                                ),
                                sx: {
                                    borderRadius: '8px', // Border radius for the inner input element
                                    color: ColorsHelper.textColor,
                                },
                            }}
                        />
                    </Box>

                    <Box>
                        <Typography sx={{ fontSize: '0.8rem', mb: 0.8, color: ColorsHelper.blackClr80 }}>Email Address</Typography>
                        <TextField
                            fullWidth
                            type='email'
                            size="small"
                            placeholder="Your Email"
                            value="alanwatson@gmail.com"
                            sx={{
                                borderRadius: '8px',  // Border radius for the overall TextField
                                color: ColorsHelper.textColor,
                            }}
                            InputProps={{
                                endAdornment: (
                                    <InputAdornment position="end">
                                        <span style={{ color: ColorsHelper.mainColor, cursor: 'pointer' }}>Change</span>
                                    </InputAdornment>
                                ),
                                sx: {
                                    borderRadius: '8px', // Border radius for the inner input element
                                    color: ColorsHelper.textColor,
                                },
                            }}
                        />
                    </Box>

                    <Box>
                        <Typography sx={{ fontSize: '0.8rem', mb: 0.8, color: ColorsHelper.blackClr80 }}>Date of Birth</Typography>
                        <StyledDatePickerWrapper>
                            <DatePicker
                                selected={startDate}
                                onChange={(date: any) => setStartDate(date)}
                                customInput={<TextField size="small" fullWidth variant="outlined" />}
                                dateFormat="MM/dd/yyyy"
                            />
                        </StyledDatePickerWrapper>
                    </Box>

                    <Box>
                        <Typography sx={{ fontSize: '0.8rem', mb: 0.8, color: ColorsHelper.blackClr80 }}>Gender</Typography>
                        <Select
                            variant="outlined"
                            fullWidth
                            value={gender} // Set the selected value to "Male"
                            onChange={handleGenderChange} // Handle the selection change
                            sx={{
                                borderRadius: '8px', // Apply a border radius to the select field
                                '& .MuiOutlinedInput-notchedOutline': {
                                    borderRadius: '8px', // Ensure the border radius is applied to the outline as well
                                },
                            }}
                        >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                        </Select>
                    </Box>
                </Box>

                <Box sx={{ bgcolor: ColorsHelper.whiteClr, borderTopLeftRadius: '10px', borderTopRightRadius: '10px', p: 2 }}>
                    <LoadingButton variant='contained' fullWidth sx={{ bgcolor: ColorsHelper.mainColor, borderRadius: '6px' }}>Update Profile</LoadingButton>
                </Box>
            </Box>
        </Box>
    )
}

export default ProfileSetting
