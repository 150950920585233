import Container from '@mui/material/Container';
import ColorsHelper from '../shared/ColorHelper';


const Layout = ({children}:any) => {
  return (
    <Container maxWidth='xs' disableGutters={true} 
    sx={{ 
      "::-webkit-scrollbar": { display: "none"}, backgroundColor:ColorsHelper.backColor}}>
        {children}
    </Container>
  )
}

export default Layout
