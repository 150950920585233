//react Imports
import { lazy, Suspense } from "react";
import { Route, Routes, useRoutes } from "react-router-dom";

//Material UI Imports
import { CircularProgress } from "@mui/material";

//Components
import Layout from "./Layout/Layout";

//Third Party Imports
import { ToastContainer, Slide } from "react-toastify";
import { RouteName } from "./constants/routeNameConstant";
import ProfileView from "./components/ProfileView/ProfileView";
import ProfileSetting from "./components/ProfileView/ProfileSetting";
import CircleProgress from "./UI/CircleProgress/CircleProgress";
import ThreeDViewer from "./components/ThreeDViewer/ThreeDViewer";

//Helpers
const SplashScreen = lazy(() => import("./components/SplashScreen/SplashScreen"))
const SignUpOrLogInScreen =  lazy(() => import("./components/SignUpOrLoInScreen/SignUpOrLogInScreen")) ;
const OTPScreen =  lazy(() => import("./components/OTPScreen/OTPScreen")) ;
const UserDetails =  lazy(() => import("./components/UserDetails/UserDetails")) ;
const Location =  lazy(() => import("./components/Location/")) ;
const Restaurants =  lazy(() => import("./components/Home/Home")) ;
const CategoriesView =  lazy(() => import("./components/CategoriesView/CategoriesView"));

// export const PublicRoute = ({ component: Component, ...rest }) => {
//     const isLoggedIn = AuthService.isLoggedIn(); //get token using service
//     document.title = rest.title;

//     return ( 
//         <Route
//         {...rest}
//         render={(props) => {
//             if (isLoggedIn) {
//                 return <Redirect to={RouteName.DEFAULT_ROUTE} />
//             } else {
//                 return (window.location.hostname === 'localhost' ? (
//                     <AuthContextProvider>
//                         <Component {...props} />
//                     </AuthContextProvider>
//                 ) : (
//                     <RedirectToLogin />
//                 ))
//             }
//         }
//             } />
//     );
// }

// export const PrivateRoute = ({ component: Component, ...rest }) => {
//     const isLoggedIn = AuthService.isLoggedIn();  //get token using service
//     document.title = rest.title;

//     return (
//         <Route
//             {...rest}
//             render={(props) =>
//                 isLoggedIn ?
//                     <>
//                         <Header />
//                         <Component {...props} />
//                     </> :
//                     // <Redirect to={RouteName.LOGIN} component={() => RedirectToLogin()} />
//                     <RedirectToLogin />
//             }
//         />
//     );
// }

//lazy Load Components


const AllRoutes = () => {

    return (
        <>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar
                newestOnTop
                closeOnClick
                rtl={false}
                pauseOnFocusLoss={false}
                draggable={false}
                pauseOnHover
                className='toast-on-top'
                transition={Slide}
            />
            <Routes>
                {/* {window.location.hostname === 'localhost' &&
                        <PublicRoute
                            path={RouteName.LOGIN}
                            component={PrivateLayout(Login)}
                            title="Login"
                            exact
                        />
                    } */}
                {/* <PrivateRoute
                        path={[RouteName.DEFAULT_ROUTE, RouteName.JOURNEY_DETAIL]}
                        component={PrivateLayout(JourneyList)}
                        title="Journey List"
                        exact
                    /> */}
                <Route
                    path={RouteName.DEFAULT_ROUTE}
                    element={
                        <Suspense fallback={<CircleProgress />}>
                            <Layout>
                                <SplashScreen />
                            </Layout>
                        </Suspense>
                    }
                />
                <Route
                    path={RouteName.SIGN_IN_ROUTE}
                    element={
                        <Suspense fallback={<CircleProgress />}>
                            <Layout>
                                <SignUpOrLogInScreen />
                            </Layout>
                        </Suspense>
                    }
                />
                <Route
                    path={RouteName.OTP_ROUTE}
                    element={
                        <Suspense fallback={<CircleProgress />}>
                            <Layout>
                                <OTPScreen />
                            </Layout>
                        </Suspense>
                    }
                />
                <Route
                    path={RouteName.LOGIN_ROUTE}
                    element={
                        <Suspense fallback={<CircleProgress />}>
                            <Layout>
                                <UserDetails />
                            </Layout>
                        </Suspense>
                    }
                />
                <Route
                    path={RouteName.LOCATION_ROUTE}
                    element={
                        <Suspense fallback={<CircleProgress />}>
                            <Layout>
                                <Location />
                            </Layout>
                        </Suspense>
                    }
                />
                <Route
                    path={RouteName.HOME}
                    element={
                        <Suspense fallback={<CircleProgress />}>
                            <Layout>
                                <Restaurants />
                            </Layout>
                        </Suspense>
                    }
                />
                <Route
                    path={RouteName.PROFILE_VIEW}
                    element={
                        <Suspense fallback={<CircleProgress />}>
                            <Layout>
                                <ProfileView />
                            </Layout>
                        </Suspense>
                    }
                />
                <Route
                    path={RouteName.PROFILE_SETTINGS}
                    element={
                        <Suspense fallback={<CircleProgress />}>
                            <Layout>
                                <ProfileSetting />
                            </Layout>
                        </Suspense>
                    }
                />
                <Route
                    path={`${RouteName.CATEGORIES_VIEW}/:category`}
                    element={
                        <Suspense fallback={<CircleProgress />}>
                            <Layout>
                                <CategoriesView />
                            </Layout>
                        </Suspense>
                    }
                />
                <Route
                    path={`/3d`}
                    element={
                        <Suspense fallback={<CircleProgress />}>
                            <Layout>
                                <ThreeDViewer />
                            </Layout>
                        </Suspense>
                    }
                />
            </Routes>
        </>
    )
}

export default AllRoutes;