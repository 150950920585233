import AllRoutes from "./routes"
import './index.css';
import AppMapProvider from './UI/CsMap/MapLoader'
import DisablePullToRefresh from "./UI/DisablePullToRefresh/DisablePullToRefresh";

const App = () => {
  return (
    <AppMapProvider>
      <DisablePullToRefresh/> 
      <AllRoutes />
    </AppMapProvider>
  )
}

export default App
