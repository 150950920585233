import React, { useState, useEffect, useRef, Suspense } from 'react';
import { Canvas, useThree } from '@react-three/fiber';
import { OrbitControls, useGLTF } from '@react-three/drei';
import { a, useSpring } from '@react-spring/three';
import * as THREE from 'three';
import modelPath1 from "../../Assets/3d/pizza.glb";
import modelPath2 from "../../Assets/3d/tasty_burger_with_fries.glb";
import modelPath3 from "../../Assets/3d/meat_skewer.glb";
import modelPath4 from "../../Assets/3d/pasta_with_meatballs_and_sausage.glb";
import modelPath5 from "../../Assets/3d/ramen_bowl.glb";
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css'; 
import { Box, Typography } from '@mui/material';

const AnimatedModel = ({ modelPath }) => {
  const { scene } = useGLTF(modelPath);
  const ref = useRef();
  const { camera } = useThree();

  useEffect(() => {
    if (ref.current) {
      const box = new THREE.Box3().setFromObject(ref.current);
      const center = box.getCenter(new THREE.Vector3());
      const size = box.getSize(new THREE.Vector3());
      const maxDim = Math.max(size.x, size.y, size.z);
      const fov = camera.fov * (Math.PI / 180);
      const cameraDistance = maxDim / (2 * Math.tan(fov / 2)) + 1;

      camera.position.set(center.x, center.y, cameraDistance);
      camera.lookAt(center);

      const controls = camera.controls;
      if (controls) {
        controls.target.copy(center);
        controls.update();
      }
    }
  }, [scene, camera]);

  const spring = useSpring({
    scale: [1, 1, 1],
    opacity: 1,
    from: { scale: [0, 0, 0], opacity: 0 },
    config: { tension: 120, friction: 20 },
    reset: true,
  });

  return (
    <a.group scale={spring.scale} ref={ref}>
      <a.primitive object={scene} />
    </a.group>
  );
};

const ThreeDViewer = () => {
  const menuItems = [
    { id: 1, name: '1', description: 'Best in Pizza', price: '13.99 USD', modelPath: modelPath1 },
    { id: 2, name: '2', description: 'Best in Burgers', price: '10.99 USD', modelPath: modelPath2 },
    { id: 3, name: '3', description: 'Best in Skewers', price: '12.99 USD', modelPath: modelPath3 },
    { id: 4, name: '4', description: 'Best in Pasta', price: '14.99 USD', modelPath: modelPath4 },
    { id: 5, name: '5', description: 'Best in Ramen', price: '11.99 USD', modelPath: modelPath5 },
  ];

  const [selectedModel, setSelectedModel] = useState(menuItems[0]);

  // Handle model selection on slide change
  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    setSelectedModel(menuItems[activeIndex]);
  };

  return (
    <div style={{ display: 'flex', flexDirection: 'column', height: '100vh', backgroundColor:"4d4d4d" }}>
      <div style={{ flex: 1 }}>
        <Canvas>
          <color attach="background" args={['#4d4d4d']} />
          <ambientLight intensity={3} />
          <pointLight position={[10, 10, 10]} />
          <OrbitControls 
            enablePan={false} 
            enableRotate={true}
            enableZoom={true}
            />
          <Suspense fallback={null}>
            <AnimatedModel modelPath={selectedModel.modelPath} />
          </Suspense>
        </Canvas>
      </div>
      <div style={{ padding: '1rem', backgroundColor:"#4d4d4d" }}>

        {/* Horizontal swiper slider for model selection */}
        <Swiper
          spaceBetween={10} // Space between slides
          grabCursor={true} // Change cursor to a grab hand
          breakpoints={{
            0: { slidesPerView: 1 },
            568: { slidesPerView: 1 },
            660: { slidesPerView: 2 },
            768: { slidesPerView: 3 },
            915: { slidesPerView: 1.2 }
          }}
          onSlideChange={handleSlideChange} // Listen for slide change
        >
          {menuItems.map((item, index) => (
            <SwiperSlide key={index}>
              <Box sx={{display:'flex', alignItems:'center', justifyContent:'space-between',gap:'1rem', borderRadius:'20px', bgcolor:'#fff', p:'1rem'}}>
                <Box>
                  <Box sx={{display:'flex', alignItems:'center', gap:'0.5rem'}}>
                    {/* Add Icon Here */}
                    <Typography sx={{fontWeight:500, fontSize:'.9rem'}}>Recommended</Typography>
                  </Box>
                  <Typography sx={{fontSize:'1.3rem', fontWeight:600}}>{item.name}</Typography>
                  <Typography sx={{color:'#9196A5'}}>{item.description}</Typography>
                  <Typography sx={{color:'#2476FF'}}>{item.price}</Typography>
                </Box>
              </Box>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};

export default ThreeDViewer;
